import React from "react";
import ContentWrapper from "components/content-wrapper";
import TicketShop from "components/ticket-shop";
import SEO from "components/seo";

const DurhamPage = () => {
  return (
    <ContentWrapper>
      <SEO title="Freshers Takeover Durham" />
      <div>
        <h1>DURHAM FRESHERS EVENTS</h1>
        <div style={{ position: "relative", width: "680px", maxWidth: "100%" }}>
          <TicketShop
            scriptString={`<script src="https://web-cdn.fixr.co/scripts/fixr-shop-widget.v1.min.js" data-fixr-shop-id="19f9c490-8013-47b1-b91d-3eeb05ad0270" data-fixr-theme="dark"></script>`}
          ></TicketShop>
        </div>
      </div>
    </ContentWrapper>
  );
};

export default DurhamPage;
